// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/logs.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message soulfire.v1.LogString
 */
export interface LogString {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
}
/**
 * @generated from protobuf message soulfire.v1.GlobalLogScope
 */
export interface GlobalLogScope {
}
/**
 * @generated from protobuf message soulfire.v1.InstanceLogScope
 */
export interface InstanceLogScope {
    /**
     * @generated from protobuf field: string instance_id = 1;
     */
    instanceId: string;
}
/**
 * @generated from protobuf message soulfire.v1.PreviousLogRequest
 */
export interface PreviousLogRequest {
    /**
     * @generated from protobuf oneof: scope
     */
    scope: {
        oneofKind: "global";
        /**
         * @generated from protobuf field: soulfire.v1.GlobalLogScope global = 1;
         */
        global: GlobalLogScope;
    } | {
        oneofKind: "instance";
        /**
         * @generated from protobuf field: soulfire.v1.InstanceLogScope instance = 2;
         */
        instance: InstanceLogScope;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: int32 count = 3;
     */
    count: number;
}
/**
 * @generated from protobuf message soulfire.v1.PreviousLogResponse
 */
export interface PreviousLogResponse {
    /**
     * @generated from protobuf field: repeated soulfire.v1.LogString messages = 1;
     */
    messages: LogString[];
}
/**
 * @generated from protobuf message soulfire.v1.LogRequest
 */
export interface LogRequest {
    /**
     * @generated from protobuf oneof: scope
     */
    scope: {
        oneofKind: "global";
        /**
         * @generated from protobuf field: soulfire.v1.GlobalLogScope global = 1;
         */
        global: GlobalLogScope;
    } | {
        oneofKind: "instance";
        /**
         * @generated from protobuf field: soulfire.v1.InstanceLogScope instance = 2;
         */
        instance: InstanceLogScope;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message soulfire.v1.LogResponse
 */
export interface LogResponse {
    /**
     * @generated from protobuf field: soulfire.v1.LogString message = 1;
     */
    message?: LogString;
}
// @generated message type with reflection information, may provide speed optimized methods
class LogString$Type extends MessageType<LogString> {
    constructor() {
        super("soulfire.v1.LogString", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogString
 */
export const LogString = new LogString$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalLogScope$Type extends MessageType<GlobalLogScope> {
    constructor() {
        super("soulfire.v1.GlobalLogScope", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GlobalLogScope
 */
export const GlobalLogScope = new GlobalLogScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceLogScope$Type extends MessageType<InstanceLogScope> {
    constructor() {
        super("soulfire.v1.InstanceLogScope", [
            { no: 1, name: "instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceLogScope
 */
export const InstanceLogScope = new InstanceLogScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviousLogRequest$Type extends MessageType<PreviousLogRequest> {
    constructor() {
        super("soulfire.v1.PreviousLogRequest", [
            { no: 1, name: "global", kind: "message", oneof: "scope", T: () => GlobalLogScope },
            { no: 2, name: "instance", kind: "message", oneof: "scope", T: () => InstanceLogScope },
            { no: 3, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.PreviousLogRequest
 */
export const PreviousLogRequest = new PreviousLogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviousLogResponse$Type extends MessageType<PreviousLogResponse> {
    constructor() {
        super("soulfire.v1.PreviousLogResponse", [
            { no: 1, name: "messages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LogString }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.PreviousLogResponse
 */
export const PreviousLogResponse = new PreviousLogResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogRequest$Type extends MessageType<LogRequest> {
    constructor() {
        super("soulfire.v1.LogRequest", [
            { no: 1, name: "global", kind: "message", oneof: "scope", T: () => GlobalLogScope },
            { no: 2, name: "instance", kind: "message", oneof: "scope", T: () => InstanceLogScope }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogRequest
 */
export const LogRequest = new LogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogResponse$Type extends MessageType<LogResponse> {
    constructor() {
        super("soulfire.v1.LogResponse", [
            { no: 1, name: "message", kind: "message", T: () => LogString }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogResponse
 */
export const LogResponse = new LogResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.LogsService
 */
export const LogsService = new ServiceType("soulfire.v1.LogsService", [
    { name: "getPrevious", options: {}, I: PreviousLogRequest, O: PreviousLogResponse },
    { name: "subscribe", serverStreaming: true, options: {}, I: LogRequest, O: LogResponse }
]);
