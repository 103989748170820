// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/config.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { UserRole } from "./common";
import { GlobalPermission } from "./common";
/**
 * @generated from protobuf message soulfire.v1.ClientDataRequest
 */
export interface ClientDataRequest {
}
/**
 * @generated from protobuf message soulfire.v1.StringSetting
 */
export interface StringSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: string def = 3;
     */
    def: string;
    /**
     * @generated from protobuf field: bool secret = 4;
     */
    secret: boolean;
    /**
     * @generated from protobuf field: bool textarea = 5;
     */
    textarea: boolean;
    /**
     * @generated from protobuf field: string placeholder = 6;
     */
    placeholder: string;
}
/**
 * @generated from protobuf message soulfire.v1.IntSetting
 */
export interface IntSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: int32 def = 3;
     */
    def: number;
    /**
     * @generated from protobuf field: int32 min = 4;
     */
    min: number;
    /**
     * @generated from protobuf field: int32 max = 5;
     */
    max: number;
    /**
     * @generated from protobuf field: int32 step = 6;
     */
    step: number;
    /**
     * @generated from protobuf field: optional string format = 7;
     */
    format?: string;
    /**
     * @generated from protobuf field: string placeholder = 8;
     */
    placeholder: string;
}
/**
 * @generated from protobuf message soulfire.v1.DoubleSetting
 */
export interface DoubleSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: double def = 3;
     */
    def: number;
    /**
     * @generated from protobuf field: double min = 4;
     */
    min: number;
    /**
     * @generated from protobuf field: double max = 5;
     */
    max: number;
    /**
     * @generated from protobuf field: double step = 6;
     */
    step: number;
    /**
     * @generated from protobuf field: optional string format = 7;
     */
    format?: string;
    /**
     * @generated from protobuf field: string placeholder = 8;
     */
    placeholder: string;
}
/**
 * @generated from protobuf message soulfire.v1.BoolSetting
 */
export interface BoolSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: bool def = 3;
     */
    def: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.ComboOption
 */
export interface ComboOption {
    /**
     * Sent to server
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Displayed to user
     *
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
}
/**
 * @generated from protobuf message soulfire.v1.ComboSetting
 */
export interface ComboSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * List of options
     *
     * @generated from protobuf field: repeated soulfire.v1.ComboOption options = 3;
     */
    options: ComboOption[];
    /**
     * @generated from protobuf field: string def = 4;
     */
    def: string;
}
/**
 * @generated from protobuf message soulfire.v1.StringListSetting
 */
export interface StringListSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string def = 3;
     */
    def: string[];
}
/**
 * @generated from protobuf message soulfire.v1.MinMaxSetting
 */
export interface MinMaxSetting {
    /**
     * @generated from protobuf field: string min_ui_name = 1;
     */
    minUiName: string;
    /**
     * @generated from protobuf field: string max_ui_name = 2;
     */
    maxUiName: string;
    /**
     * @generated from protobuf field: string min_description = 3;
     */
    minDescription: string;
    /**
     * @generated from protobuf field: string max_description = 4;
     */
    maxDescription: string;
    /**
     * @generated from protobuf field: int32 min_def = 5;
     */
    minDef: number;
    /**
     * @generated from protobuf field: int32 max_def = 6;
     */
    maxDef: number;
    /**
     * @generated from protobuf field: int32 min = 7;
     */
    min: number;
    /**
     * @generated from protobuf field: int32 max = 8;
     */
    max: number;
    /**
     * @generated from protobuf field: int32 step = 9;
     */
    step: number;
    /**
     * @generated from protobuf field: optional string format = 10;
     */
    format?: string;
    /**
     * @generated from protobuf field: string min_placeholder = 11;
     */
    minPlaceholder: string;
    /**
     * @generated from protobuf field: string max_placeholder = 12;
     */
    maxPlaceholder: string;
}
/**
 * A single setting type with optional default value
 *
 * @generated from protobuf message soulfire.v1.SettingType
 */
export interface SettingType {
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "string";
        /**
         * @generated from protobuf field: soulfire.v1.StringSetting string = 1;
         */
        string: StringSetting;
    } | {
        oneofKind: "int";
        /**
         * @generated from protobuf field: soulfire.v1.IntSetting int = 2;
         */
        int: IntSetting;
    } | {
        oneofKind: "double";
        /**
         * @generated from protobuf field: soulfire.v1.DoubleSetting double = 3;
         */
        double: DoubleSetting;
    } | {
        oneofKind: "bool";
        /**
         * @generated from protobuf field: soulfire.v1.BoolSetting bool = 4;
         */
        bool: BoolSetting;
    } | {
        oneofKind: "combo";
        /**
         * @generated from protobuf field: soulfire.v1.ComboSetting combo = 5;
         */
        combo: ComboSetting;
    } | {
        oneofKind: "stringList";
        /**
         * @generated from protobuf field: soulfire.v1.StringListSetting string_list = 6;
         */
        stringList: StringListSetting;
    } | {
        oneofKind: "minMax";
        /**
         * @generated from protobuf field: soulfire.v1.MinMaxSetting min_max = 7;
         */
        minMax: MinMaxSetting;
    } | {
        oneofKind: undefined;
    };
}
/**
 * A entry in the settings page
 *
 * @generated from protobuf message soulfire.v1.SettingEntry
 */
export interface SettingEntry {
    /**
     * Basically we only send a kv map to the server with every setting entry
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: soulfire.v1.SettingType type = 4;
     */
    type?: SettingType;
}
/**
 * @generated from protobuf message soulfire.v1.SettingsPage
 */
export interface SettingsPage {
    /**
     * @generated from protobuf field: optional string owning_plugin = 2;
     */
    owningPlugin?: string;
    /**
     * The name of the page for these settings
     *
     * @generated from protobuf field: string page_name = 3;
     */
    pageName: string;
    /**
     * What namespace the settings of this page represent
     *
     * @generated from protobuf field: string namespace = 4;
     */
    namespace: string;
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingEntry entries = 5;
     */
    entries: SettingEntry[];
    /**
     * https://lucide.dev icon id for this page (Usually rendered left of the page name)
     *
     * @generated from protobuf field: string icon_id = 6;
     */
    iconId: string;
}
/**
 * @generated from protobuf message soulfire.v1.GlobalPermissionState
 */
export interface GlobalPermissionState {
    /**
     * @generated from protobuf field: soulfire.v1.GlobalPermission global_permission = 1;
     */
    globalPermission: GlobalPermission;
    /**
     * @generated from protobuf field: bool granted = 2;
     */
    granted: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.ServerPlugin
 */
export interface ServerPlugin {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string author = 4;
     */
    author: string;
    /**
     * @generated from protobuf field: string license = 5;
     */
    license: string;
    /**
     * @generated from protobuf field: string website = 6;
     */
    website: string;
}
/**
 * @generated from protobuf message soulfire.v1.ClientDataResponse
 */
export interface ClientDataResponse {
    /**
     * @generated from protobuf field: string id = 6;
     */
    id: string;
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * @generated from protobuf field: soulfire.v1.UserRole role = 7;
     */
    role: UserRole;
    /**
     * @generated from protobuf field: string email = 8;
     */
    email: string;
    /**
     * @generated from protobuf field: repeated soulfire.v1.GlobalPermissionState server_permissions = 2;
     */
    serverPermissions: GlobalPermissionState[];
    /**
     * @generated from protobuf field: repeated soulfire.v1.ServerPlugin plugins = 3;
     */
    plugins: ServerPlugin[];
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingsPage server_settings = 9;
     */
    serverSettings: SettingsPage[];
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingsPage instance_settings = 10;
     */
    instanceSettings: SettingsPage[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ClientDataRequest$Type extends MessageType<ClientDataRequest> {
    constructor() {
        super("soulfire.v1.ClientDataRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ClientDataRequest
 */
export const ClientDataRequest = new ClientDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StringSetting$Type extends MessageType<StringSetting> {
    constructor() {
        super("soulfire.v1.StringSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "secret", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "textarea", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.StringSetting
 */
export const StringSetting = new StringSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntSetting$Type extends MessageType<IntSetting> {
    constructor() {
        super("soulfire.v1.IntSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "min", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "max", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "step", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "format", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.IntSetting
 */
export const IntSetting = new IntSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoubleSetting$Type extends MessageType<DoubleSetting> {
    constructor() {
        super("soulfire.v1.DoubleSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "min", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "max", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "step", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "format", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.DoubleSetting
 */
export const DoubleSetting = new DoubleSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BoolSetting$Type extends MessageType<BoolSetting> {
    constructor() {
        super("soulfire.v1.BoolSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.BoolSetting
 */
export const BoolSetting = new BoolSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ComboOption$Type extends MessageType<ComboOption> {
    constructor() {
        super("soulfire.v1.ComboOption", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ComboOption
 */
export const ComboOption = new ComboOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ComboSetting$Type extends MessageType<ComboSetting> {
    constructor() {
        super("soulfire.v1.ComboSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "options", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ComboOption },
            { no: 4, name: "def", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ComboSetting
 */
export const ComboSetting = new ComboSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StringListSetting$Type extends MessageType<StringListSetting> {
    constructor() {
        super("soulfire.v1.StringListSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.StringListSetting
 */
export const StringListSetting = new StringListSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinMaxSetting$Type extends MessageType<MinMaxSetting> {
    constructor() {
        super("soulfire.v1.MinMaxSetting", [
            { no: 1, name: "min_ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "max_ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "min_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "max_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "min_def", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "max_def", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "min", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "max", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "step", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "format", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "min_placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "max_placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinMaxSetting
 */
export const MinMaxSetting = new MinMaxSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingType$Type extends MessageType<SettingType> {
    constructor() {
        super("soulfire.v1.SettingType", [
            { no: 1, name: "string", kind: "message", oneof: "value", T: () => StringSetting },
            { no: 2, name: "int", kind: "message", oneof: "value", T: () => IntSetting },
            { no: 3, name: "double", kind: "message", oneof: "value", T: () => DoubleSetting },
            { no: 4, name: "bool", kind: "message", oneof: "value", T: () => BoolSetting },
            { no: 5, name: "combo", kind: "message", oneof: "value", T: () => ComboSetting },
            { no: 6, name: "string_list", kind: "message", oneof: "value", T: () => StringListSetting },
            { no: 7, name: "min_max", kind: "message", oneof: "value", T: () => MinMaxSetting }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingType
 */
export const SettingType = new SettingType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingEntry$Type extends MessageType<SettingEntry> {
    constructor() {
        super("soulfire.v1.SettingEntry", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "message", T: () => SettingType }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingEntry
 */
export const SettingEntry = new SettingEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingsPage$Type extends MessageType<SettingsPage> {
    constructor() {
        super("soulfire.v1.SettingsPage", [
            { no: 2, name: "owning_plugin", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "page_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingEntry },
            { no: 6, name: "icon_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingsPage
 */
export const SettingsPage = new SettingsPage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalPermissionState$Type extends MessageType<GlobalPermissionState> {
    constructor() {
        super("soulfire.v1.GlobalPermissionState", [
            { no: 1, name: "global_permission", kind: "enum", T: () => ["soulfire.v1.GlobalPermission", GlobalPermission] },
            { no: 2, name: "granted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GlobalPermissionState
 */
export const GlobalPermissionState = new GlobalPermissionState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerPlugin$Type extends MessageType<ServerPlugin> {
    constructor() {
        super("soulfire.v1.ServerPlugin", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "author", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "license", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "website", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ServerPlugin
 */
export const ServerPlugin = new ServerPlugin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientDataResponse$Type extends MessageType<ClientDataResponse> {
    constructor() {
        super("soulfire.v1.ClientDataResponse", [
            { no: 6, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "role", kind: "enum", T: () => ["soulfire.v1.UserRole", UserRole] },
            { no: 8, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "server_permissions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GlobalPermissionState },
            { no: 3, name: "plugins", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServerPlugin },
            { no: 9, name: "server_settings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingsPage },
            { no: 10, name: "instance_settings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingsPage }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ClientDataResponse
 */
export const ClientDataResponse = new ClientDataResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.ConfigService
 */
export const ConfigService = new ServiceType("soulfire.v1.ConfigService", [
    { name: "getClientData", options: {}, I: ClientDataRequest, O: ClientDataResponse }
]);
