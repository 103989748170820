// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/user.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { UserRole } from "./common";
/**
 * @generated from protobuf message soulfire.v1.UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * @generated from protobuf field: soulfire.v1.UserRole role = 2;
     */
    role: UserRole;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
}
/**
 * @generated from protobuf message soulfire.v1.UserCreateResponse
 */
export interface UserCreateResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.UserDeleteRequest
 */
export interface UserDeleteRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.UserDeleteResponse
 */
export interface UserDeleteResponse {
}
/**
 * @generated from protobuf message soulfire.v1.UserListRequest
 */
export interface UserListRequest {
}
/**
 * @generated from protobuf message soulfire.v1.UserListResponse
 */
export interface UserListResponse {
    /**
     * @generated from protobuf field: repeated soulfire.v1.UserListResponse.User users = 1;
     */
    users: UserListResponse_User[];
}
/**
 * @generated from protobuf message soulfire.v1.UserListResponse.User
 */
export interface UserListResponse_User {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string username = 2;
     */
    username: string;
    /**
     * @generated from protobuf field: soulfire.v1.UserRole role = 3;
     */
    role: UserRole;
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
}
/**
 * @generated from protobuf message soulfire.v1.UserInfoRequest
 */
export interface UserInfoRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.UserInfoResponse
 */
export interface UserInfoResponse {
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * @generated from protobuf field: soulfire.v1.UserRole role = 2;
     */
    role: UserRole;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class UserCreateRequest$Type extends MessageType<UserCreateRequest> {
    constructor() {
        super("soulfire.v1.UserCreateRequest", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "role", kind: "enum", T: () => ["soulfire.v1.UserRole", UserRole] },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserCreateRequest
 */
export const UserCreateRequest = new UserCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserCreateResponse$Type extends MessageType<UserCreateResponse> {
    constructor() {
        super("soulfire.v1.UserCreateResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserCreateResponse
 */
export const UserCreateResponse = new UserCreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserDeleteRequest$Type extends MessageType<UserDeleteRequest> {
    constructor() {
        super("soulfire.v1.UserDeleteRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserDeleteRequest
 */
export const UserDeleteRequest = new UserDeleteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserDeleteResponse$Type extends MessageType<UserDeleteResponse> {
    constructor() {
        super("soulfire.v1.UserDeleteResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserDeleteResponse
 */
export const UserDeleteResponse = new UserDeleteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserListRequest$Type extends MessageType<UserListRequest> {
    constructor() {
        super("soulfire.v1.UserListRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserListRequest
 */
export const UserListRequest = new UserListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserListResponse$Type extends MessageType<UserListResponse> {
    constructor() {
        super("soulfire.v1.UserListResponse", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserListResponse_User }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserListResponse
 */
export const UserListResponse = new UserListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserListResponse_User$Type extends MessageType<UserListResponse_User> {
    constructor() {
        super("soulfire.v1.UserListResponse.User", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role", kind: "enum", T: () => ["soulfire.v1.UserRole", UserRole] },
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserListResponse.User
 */
export const UserListResponse_User = new UserListResponse_User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInfoRequest$Type extends MessageType<UserInfoRequest> {
    constructor() {
        super("soulfire.v1.UserInfoRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserInfoRequest
 */
export const UserInfoRequest = new UserInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInfoResponse$Type extends MessageType<UserInfoResponse> {
    constructor() {
        super("soulfire.v1.UserInfoResponse", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "role", kind: "enum", T: () => ["soulfire.v1.UserRole", UserRole] },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UserInfoResponse
 */
export const UserInfoResponse = new UserInfoResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.UserService
 */
export const UserService = new ServiceType("soulfire.v1.UserService", [
    { name: "createUser", options: {}, I: UserCreateRequest, O: UserCreateResponse },
    { name: "deleteUser", options: {}, I: UserDeleteRequest, O: UserDeleteResponse },
    { name: "listUsers", options: {}, I: UserListRequest, O: UserListResponse },
    { name: "getUserInfo", options: {}, I: UserInfoRequest, O: UserInfoResponse }
]);
