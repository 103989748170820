// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/instance.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { InstancePermission } from "./common";
import { ProxyProto } from "./common";
import { MinecraftAccountProto } from "./common";
import { SettingsNamespace } from "./common";
/**
 * @generated from protobuf message soulfire.v1.InstanceConfig
 */
export interface InstanceConfig {
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingsNamespace settings = 1;
     */
    settings: SettingsNamespace[];
    /**
     * @generated from protobuf field: repeated soulfire.v1.MinecraftAccountProto accounts = 2;
     */
    accounts: MinecraftAccountProto[];
    /**
     * @generated from protobuf field: repeated soulfire.v1.ProxyProto proxies = 3;
     */
    proxies: ProxyProto[];
}
/**
 * @generated from protobuf message soulfire.v1.InstanceCreateRequest
 */
export interface InstanceCreateRequest {
    /**
     * @generated from protobuf field: string friendlyName = 1;
     */
    friendlyName: string;
}
/**
 * @generated from protobuf message soulfire.v1.InstanceCreateResponse
 */
export interface InstanceCreateResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.InstanceDeleteRequest
 */
export interface InstanceDeleteRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.InstanceDeleteResponse
 */
export interface InstanceDeleteResponse {
}
/**
 * @generated from protobuf message soulfire.v1.InstanceListRequest
 */
export interface InstanceListRequest {
}
/**
 * @generated from protobuf message soulfire.v1.InstanceListResponse
 */
export interface InstanceListResponse {
    /**
     * @generated from protobuf field: repeated soulfire.v1.InstanceListResponse.Instance instances = 1;
     */
    instances: InstanceListResponse_Instance[];
}
/**
 * @generated from protobuf message soulfire.v1.InstanceListResponse.Instance
 */
export interface InstanceListResponse_Instance {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string friendly_name = 2;
     */
    friendlyName: string;
    /**
     * @generated from protobuf field: string icon = 5;
     */
    icon: string;
    /**
     * @generated from protobuf field: soulfire.v1.InstanceState state = 3;
     */
    state: InstanceState;
    /**
     * @generated from protobuf field: repeated soulfire.v1.InstancePermissionState instance_permissions = 4;
     */
    instancePermissions: InstancePermissionState[];
}
/**
 * @generated from protobuf message soulfire.v1.InstancePermissionState
 */
export interface InstancePermissionState {
    /**
     * @generated from protobuf field: soulfire.v1.InstancePermission instance_permission = 1;
     */
    instancePermission: InstancePermission;
    /**
     * @generated from protobuf field: bool granted = 2;
     */
    granted: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.InstanceInfoRequest
 */
export interface InstanceInfoRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.InstanceInfoResponse
 */
export interface InstanceInfoResponse {
    /**
     * @generated from protobuf field: string friendly_name = 1;
     */
    friendlyName: string;
    /**
     * @generated from protobuf field: string icon = 5;
     */
    icon: string;
    /**
     * @generated from protobuf field: soulfire.v1.InstanceConfig config = 2;
     */
    config?: InstanceConfig;
    /**
     * @generated from protobuf field: soulfire.v1.InstanceState state = 3;
     */
    state: InstanceState;
    /**
     * @generated from protobuf field: repeated soulfire.v1.InstancePermissionState instance_permissions = 4;
     */
    instancePermissions: InstancePermissionState[];
}
/**
 * @generated from protobuf message soulfire.v1.InstanceUpdateMetaRequest
 */
export interface InstanceUpdateMetaRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf oneof: meta
     */
    meta: {
        oneofKind: "friendlyName";
        /**
         * @generated from protobuf field: string friendly_name = 2;
         */
        friendlyName: string;
    } | {
        oneofKind: "icon";
        /**
         * @generated from protobuf field: string icon = 3;
         */
        icon: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message soulfire.v1.InstanceUpdateMetaResponse
 */
export interface InstanceUpdateMetaResponse {
}
/**
 * @generated from protobuf message soulfire.v1.InstanceUpdateConfigRequest
 */
export interface InstanceUpdateConfigRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: soulfire.v1.InstanceConfig config = 2;
     */
    config?: InstanceConfig;
}
/**
 * @generated from protobuf message soulfire.v1.InstanceUpdateConfigResponse
 */
export interface InstanceUpdateConfigResponse {
}
/**
 * @generated from protobuf message soulfire.v1.InstanceStateChangeRequest
 */
export interface InstanceStateChangeRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: soulfire.v1.InstanceState state = 2;
     */
    state: InstanceState;
}
/**
 * @generated from protobuf message soulfire.v1.InstanceStateChangeResponse
 */
export interface InstanceStateChangeResponse {
}
/**
 * @generated from protobuf enum soulfire.v1.InstanceState
 */
export enum InstanceState {
    /**
     * @generated from protobuf enum value: STARTING = 0;
     */
    STARTING = 0,
    /**
     * @generated from protobuf enum value: RUNNING = 1;
     */
    RUNNING = 1,
    /**
     * @generated from protobuf enum value: PAUSED = 2;
     */
    PAUSED = 2,
    /**
     * @generated from protobuf enum value: STOPPING = 3;
     */
    STOPPING = 3,
    /**
     * @generated from protobuf enum value: STOPPED = 4;
     */
    STOPPED = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class InstanceConfig$Type extends MessageType<InstanceConfig> {
    constructor() {
        super("soulfire.v1.InstanceConfig", [
            { no: 1, name: "settings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingsNamespace },
            { no: 2, name: "accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MinecraftAccountProto },
            { no: 3, name: "proxies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProxyProto }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceConfig
 */
export const InstanceConfig = new InstanceConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceCreateRequest$Type extends MessageType<InstanceCreateRequest> {
    constructor() {
        super("soulfire.v1.InstanceCreateRequest", [
            { no: 1, name: "friendlyName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceCreateRequest
 */
export const InstanceCreateRequest = new InstanceCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceCreateResponse$Type extends MessageType<InstanceCreateResponse> {
    constructor() {
        super("soulfire.v1.InstanceCreateResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceCreateResponse
 */
export const InstanceCreateResponse = new InstanceCreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceDeleteRequest$Type extends MessageType<InstanceDeleteRequest> {
    constructor() {
        super("soulfire.v1.InstanceDeleteRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceDeleteRequest
 */
export const InstanceDeleteRequest = new InstanceDeleteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceDeleteResponse$Type extends MessageType<InstanceDeleteResponse> {
    constructor() {
        super("soulfire.v1.InstanceDeleteResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceDeleteResponse
 */
export const InstanceDeleteResponse = new InstanceDeleteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceListRequest$Type extends MessageType<InstanceListRequest> {
    constructor() {
        super("soulfire.v1.InstanceListRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceListRequest
 */
export const InstanceListRequest = new InstanceListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceListResponse$Type extends MessageType<InstanceListResponse> {
    constructor() {
        super("soulfire.v1.InstanceListResponse", [
            { no: 1, name: "instances", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InstanceListResponse_Instance }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceListResponse
 */
export const InstanceListResponse = new InstanceListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceListResponse_Instance$Type extends MessageType<InstanceListResponse_Instance> {
    constructor() {
        super("soulfire.v1.InstanceListResponse.Instance", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "friendly_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["soulfire.v1.InstanceState", InstanceState] },
            { no: 4, name: "instance_permissions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InstancePermissionState }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceListResponse.Instance
 */
export const InstanceListResponse_Instance = new InstanceListResponse_Instance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstancePermissionState$Type extends MessageType<InstancePermissionState> {
    constructor() {
        super("soulfire.v1.InstancePermissionState", [
            { no: 1, name: "instance_permission", kind: "enum", T: () => ["soulfire.v1.InstancePermission", InstancePermission] },
            { no: 2, name: "granted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstancePermissionState
 */
export const InstancePermissionState = new InstancePermissionState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceInfoRequest$Type extends MessageType<InstanceInfoRequest> {
    constructor() {
        super("soulfire.v1.InstanceInfoRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceInfoRequest
 */
export const InstanceInfoRequest = new InstanceInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceInfoResponse$Type extends MessageType<InstanceInfoResponse> {
    constructor() {
        super("soulfire.v1.InstanceInfoResponse", [
            { no: 1, name: "friendly_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "config", kind: "message", T: () => InstanceConfig },
            { no: 3, name: "state", kind: "enum", T: () => ["soulfire.v1.InstanceState", InstanceState] },
            { no: 4, name: "instance_permissions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InstancePermissionState }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceInfoResponse
 */
export const InstanceInfoResponse = new InstanceInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceUpdateMetaRequest$Type extends MessageType<InstanceUpdateMetaRequest> {
    constructor() {
        super("soulfire.v1.InstanceUpdateMetaRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "friendly_name", kind: "scalar", oneof: "meta", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "icon", kind: "scalar", oneof: "meta", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceUpdateMetaRequest
 */
export const InstanceUpdateMetaRequest = new InstanceUpdateMetaRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceUpdateMetaResponse$Type extends MessageType<InstanceUpdateMetaResponse> {
    constructor() {
        super("soulfire.v1.InstanceUpdateMetaResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceUpdateMetaResponse
 */
export const InstanceUpdateMetaResponse = new InstanceUpdateMetaResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceUpdateConfigRequest$Type extends MessageType<InstanceUpdateConfigRequest> {
    constructor() {
        super("soulfire.v1.InstanceUpdateConfigRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "config", kind: "message", T: () => InstanceConfig }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceUpdateConfigRequest
 */
export const InstanceUpdateConfigRequest = new InstanceUpdateConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceUpdateConfigResponse$Type extends MessageType<InstanceUpdateConfigResponse> {
    constructor() {
        super("soulfire.v1.InstanceUpdateConfigResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceUpdateConfigResponse
 */
export const InstanceUpdateConfigResponse = new InstanceUpdateConfigResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceStateChangeRequest$Type extends MessageType<InstanceStateChangeRequest> {
    constructor() {
        super("soulfire.v1.InstanceStateChangeRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "state", kind: "enum", T: () => ["soulfire.v1.InstanceState", InstanceState] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceStateChangeRequest
 */
export const InstanceStateChangeRequest = new InstanceStateChangeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceStateChangeResponse$Type extends MessageType<InstanceStateChangeResponse> {
    constructor() {
        super("soulfire.v1.InstanceStateChangeResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceStateChangeResponse
 */
export const InstanceStateChangeResponse = new InstanceStateChangeResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.InstanceService
 */
export const InstanceService = new ServiceType("soulfire.v1.InstanceService", [
    { name: "createInstance", options: {}, I: InstanceCreateRequest, O: InstanceCreateResponse },
    { name: "deleteInstance", options: {}, I: InstanceDeleteRequest, O: InstanceDeleteResponse },
    { name: "listInstances", options: {}, I: InstanceListRequest, O: InstanceListResponse },
    { name: "getInstanceInfo", options: {}, I: InstanceInfoRequest, O: InstanceInfoResponse },
    { name: "updateInstanceMeta", options: {}, I: InstanceUpdateMetaRequest, O: InstanceUpdateMetaResponse },
    { name: "updateInstanceConfig", options: {}, I: InstanceUpdateConfigRequest, O: InstanceUpdateConfigResponse },
    { name: "changeInstanceState", options: {}, I: InstanceStateChangeRequest, O: InstanceStateChangeResponse }
]);
