// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/proxy-check.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { ProxyProto } from "./common";
/**
 * @generated from protobuf message soulfire.v1.ProxyCheckRequest
 */
export interface ProxyCheckRequest {
    /**
     * @generated from protobuf field: string instance_id = 1;
     */
    instanceId: string;
    /**
     * @generated from protobuf field: repeated soulfire.v1.ProxyProto proxy = 2;
     */
    proxy: ProxyProto[];
}
/**
 * @generated from protobuf message soulfire.v1.ProxyCheckResponseSingle
 */
export interface ProxyCheckResponseSingle {
    /**
     * @generated from protobuf field: soulfire.v1.ProxyProto proxy = 1;
     */
    proxy?: ProxyProto;
    /**
     * @generated from protobuf field: bool valid = 2;
     */
    valid: boolean;
    /**
     * @generated from protobuf field: int32 latency = 3;
     */
    latency: number;
    /**
     * @generated from protobuf field: string real_ip = 4;
     */
    realIp: string;
}
/**
 * @generated from protobuf message soulfire.v1.ProxyCheckResponse
 */
export interface ProxyCheckResponse {
    /**
     * @generated from protobuf field: repeated soulfire.v1.ProxyCheckResponseSingle response = 1;
     */
    response: ProxyCheckResponseSingle[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ProxyCheckRequest$Type extends MessageType<ProxyCheckRequest> {
    constructor() {
        super("soulfire.v1.ProxyCheckRequest", [
            { no: 1, name: "instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "proxy", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProxyProto }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ProxyCheckRequest
 */
export const ProxyCheckRequest = new ProxyCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProxyCheckResponseSingle$Type extends MessageType<ProxyCheckResponseSingle> {
    constructor() {
        super("soulfire.v1.ProxyCheckResponseSingle", [
            { no: 1, name: "proxy", kind: "message", T: () => ProxyProto },
            { no: 2, name: "valid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "latency", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "real_ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ProxyCheckResponseSingle
 */
export const ProxyCheckResponseSingle = new ProxyCheckResponseSingle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProxyCheckResponse$Type extends MessageType<ProxyCheckResponse> {
    constructor() {
        super("soulfire.v1.ProxyCheckResponse", [
            { no: 1, name: "response", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProxyCheckResponseSingle }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ProxyCheckResponse
 */
export const ProxyCheckResponse = new ProxyCheckResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.ProxyCheckService
 */
export const ProxyCheckService = new ServiceType("soulfire.v1.ProxyCheckService", [
    { name: "check", options: {}, I: ProxyCheckRequest, O: ProxyCheckResponse }
]);
